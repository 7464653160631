






























































































































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productservice from "@/services/product-service";
import ProjectService from "@/services/project-service";

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar
  },
})
export default class StockList extends Vue {
  public obj: any = {};
  public currentItem: any = null;
  public currentIndex: number = -1;
  public title: string = "";
  public rows = 100;
    
  public currentPage = 1
  public items:any = []
  public master_items :any = [];
  public transfer_items :any = [];

  
  public ddl_item:any = []
  public ddl_item_code: any = {};

  public from_ddl_project:any = []
  from_ddl_project_code: any = {};

  public to_ddl_project:any = []
  to_ddl_project_code: any = {};
  
          
        

  create_stock() {
    let data = this.obj;
    data['to_project_title']  = this.to_ddl_project_code['title']
    data['to_project']  = this.to_ddl_project_code['id']
    // data['from_project_title']  = this.from_ddl_project_code['title']
    // data['from_project']  = this.from_ddl_project_code['id']
    data['type']  = 'Inn'
    let params = {
      master : data,
      items : this.transfer_items
    }

    productservice.create_item_stock(params)
      .then((response) => {
        this.obj = {};
        let id = response.data['id']
        this.$router.push({ path: `/product/stock/list-in-print/${id}` });
        this.to_ddl_project_code = {};
        this.to_ddl_project = [];
        this.from_ddl_project_code = {};
        this.from_ddl_project = [];
        this.master_items = [];
        this.transfer_items = [];

      })
      .catch((e) => {
        console.log(e);
      });
    }

  refreshList() {
    this.get_items();
    this.currentItem = null;
    this.currentIndex = -1;
  }


  from_search_project(title: any){
    if(title.length > 4){
      ProjectService.getByTitle(title)
      .then((response) => {  
        this.from_ddl_project = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.from_ddl_project = [];} 
  }

  from_get_project(data: any){
    this.from_ddl_project_code = data;
    if(this.from_ddl_project_code){this.from_ddl_project = [];}
  }


  to_search_project(title: any){
    if(title.length > 4){
      ProjectService.getByTitle(title)
      .then((response) => {  
        this.to_ddl_project = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.to_ddl_project = [];} 
  }

   to_get_project(data: any){
    this.to_ddl_project_code = data;
    if(this.to_ddl_project_code){this.to_ddl_project = [];}
  }

  search_item(title: any){
    if(title.length > 4){
      productservice.getByTitle(title)
      .then((response) => {  
        this.master_items = response.data.result;      
        })
      .catch((e) => {
        console.log(e);
      });
    }
    if(title.length == 0){this.master_items = [];} 
  }

 get_items() {
    productservice.get_item()
      .then((response) => {
        this.master_items = response.data;
      })
      .catch((e) => {
        console.log(e);
      });   
  }

  add_item_to_transfer(data: any){
    data['qty'] = 0
    this.transfer_items.push(data)
  }
  mounted() {
    // this.get_items();
    this.obj = {};
  }
}
